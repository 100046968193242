<template>
	<div class="landing-bg full-page">
					<navbar />


		<template>
			<div class="landing-hero">
				<h1 class="landing-title text-bold" style="text-align: center">Centre d'études doctorales en Droit</h1>
				<h1 class="landing-title text-bold" style="text-align: center; margin-bottom: 60px !important;">et Economie - Agdal</h1>
				<h2 class="landing-title" style="font-size: 32px">LA PLATEFORME DE CANDIDATURE</h2>
				<h2 class="landing-title text-bold" style="font-size: 32px">ET D’INSCRIPTION AU CYCLE DOCTORAL</h2>

				<div class="landing-buttons">
<!--					<button class="landing-button bg-orange" @click="goToInscription">Inscription</button>-->
					<button class="landing-button" @click="goToLogin">Se connecter</button>
				</div>

			</div>
		</template>

			<transition name="fade" mode="out-in">
				<!--current-route-component-->
				<router-view :key="$route.fullPath" ></router-view>
			</transition>

	</div>
</template>

<script>
import navbar from "@/components/landingNav.vue"

export default {
		name: "Landing",
		components: {
			navbar,
		},
		methods: {
			goToLogin() {
					this.$router.push({name: 'login'});
			},
      goToInscription() {
        this.$router.push({name: 'inscription'});
      }
		},
	};
</script>
<style scoped>
.landing-bg {
    background-image: url("../assets/images/bg_landing.jpg") !important;
    background-size: cover;
	/* height: 100%; */
	/* background-color: var(--main-color-red); */
}
.full-page{
	  /* display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center; */
    height: 100vh;
    width: 100vw;
}

.landing-hero{
	margin-top: 5em;
	padding: 0em 2em;
}
.landing-title{
	font-size: 3.5em;
	font-weight: 400;
	text-transform: uppercase;
	line-height: 1;
	color: var(--main-color-blue);
}
.landing-button{
	padding: 1em 2em;
	margin:0em 0.5em;
	background-color: var(--main-color-blue);
	color:#FFFFFF;
	font-weight: 700;
	text-transform: uppercase;
	cursor: pointer;
	line-height: 1;
	border:none;
}
.landing-buttons{
	display: flex;
	align-items: center;
	margin-top: 3em;
	margin-left: 1em ;
}
@media all and (max-width:700px){
	.landing-title{
		font-size: 2.5em;
		text-align: center;
	}
	.landing-buttons{
		justify-content: center;
		margin-left: 0 ;
	}
}
</style>
